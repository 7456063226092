import * as React from "react";
import ServicioItem from "./servicioItem.js";

class Servicios extends React.Component {
  render() {
    return (
      <section className="content" id="sevicios-c">
        <div className="banner">
          <h1>Soluciones Tecnológicas para tu Empresa</h1>
        </div>

        <div className="content-services">
          <div className="column-3" id="servicios-one">
            <ServicioItem
              image="/img/wheelprocess.png"
              title="Soluciones IT adaptadas a sus necesidades"
              text="Ofrecemos a todo tipo de empresas y administraciones Soluciones
            Informáticas adaptadas a sus necesidades. Nos preocupamos de todo lo
            referente a la informática de su negocio y organización, para que no
            tenga que preocuparse de nada más. Dirigimos el mantenimiento tanto
            de pequeñas y medianas empresas como de grandes organizaciones.
            Además, estamos especializados en estas últimas y contamos con el
            mejor equipo técnico y humano a su servicio."
            />

            <ServicioItem
              image="/img/information.png"
              title="Mantenimiento y Soporte"
              text="Estamos especializados en el mantenimiento informático para
              empresas y organizaciones, contando con un equipo técnico
              altamente cualificado. Realizamos el mantenimiento informático y
              damos soporte integral para todas las plataformas y para todos
              los sistemas operativos así como periféricos como impresoras,
              laptops, servidores y ambientes virtualizados."
            />

            <ServicioItem
              image="/img/space.png"
              title="Servicios Outsourcing"
              text="Servicios de outsourcing contratando a personal externo
                específico para su organización. Seleccionamos a los mejores
                especialistas del mercado con el objetivo de proporcionar un
                servicio de calidad. Debido a nuestra amplia experiencia,
                podemos afirmar que este servicio proporcionará una considerable
                reducción de costes y optimización de los recursos."
            />
          </div>

          <div className="column-3" id="servicios-two">
            <ServicioItem
              image="/img/file.png"
              title="Seguridad IT"
              text="Garantizamos la seguridad de la información, confidencialidad,
              integridad y efectividad, en un marco de disponibilidad que
              permita la continuidad de los procesos del negocio"
            />

            <ServicioItem
              image="/img/worldwide.png"
              title="Networking"
              text="Disponer de una red y sistemas de comunicación fiables, seguros
              y flexibles es básico para el buen desarrollo de su negocio. Le
              ayudaremos a optimizar y unificar su infraestructura y servicios
              de comunicaciones para reducir costes y mejorar la
              productividad."
            />

            <ServicioItem
              image="/img/wnetwork.png"
              title="Cableado Estructurado"
              text="Planificación e instalación de soluciones de cableado
              estructurado tanto cobre como fibra óptica. Realizamos
              certificaciones y auditorías para sus infraestructuras de
              acuerdo con los estándares y normativas internacionales."
            />
          </div>
        </div>
      </section>
    );
  }
}

export default Servicios;
