import * as React from "react";

class Somos extends React.Component {
  render() {
    return (
      <section className="content" id="somos-c">
        <div className="banner-s">
          <img src={process.env.PUBLIC_URL + "/img/somos.jpg"} alt="" />
        </div>

        <div className="card mb-3">
          <div className="row no-gutters align-items-center">
            <div className="col-md-4">
              <img
                src={process.env.PUBLIC_URL + "/img/netwonPaises.jpg"}
                className="card-img"
                alt="..."
              />
            </div>
            <div className="col-md-8">
              <div className="card-body">
                <h1 className="card-title">¿Quiénes somos?</h1>
                <p className="card-text content-services text-justify">
                  <article className="somos">
                    <p>
                      <b>Netwon</b> es un integrador de soluciones de tecnología
                      de información (TI) con presencia en el mercado de Chile
                      desde hace 3 años y desde el 2020 con presencia regional
                      en Perú y Colombia. Contamos con profesionales capacitados
                      y certificados con más de 15 años de experiencia en el
                      mercado LATAM.
                    </p>

                    <p>
                      Nuestra misión es ser una organización dedicada al
                      servicio de continuidad operativa y seguridad de la
                      infraestructura tecnológica de las empresas, implementando
                      soluciones adaptadas a las necesidades de nuestros
                      clientes, cumpliendo con estándares de calidad y
                      excelencia en cada proyecto y servicio ejecutados para
                      cumplir con la satisfacción de nuestros clientes.
                    </p>

                    <p>
                      Nuestra visión es ser una empresa consolidada y reconocida
                      en el mercado a nivel nacional e internacional, a través
                      del profesionalismo empleado en cada proyecto y/o servicio
                      prestado, con la obtención del reconocimiento de parte de
                      nuestros clientes y proveedores como una empresa con
                      personal capacitado y certificado en las mejores
                      tecnologías del mercado.
                    </p>

                    <p>
                      Nuestros servicios van desde la consultoría e ingeniería
                      en las áreas de seguridad informática y TI, hasta el
                      desarrollo de software, suministro de equipamiento e
                      integración de sistemas llave en mano y soporte a las
                      operaciones.
                    </p>

                    <p>
                      Para poner al servicio de nuestros clientes la forma de
                      hacer en que se basa nuestro trabajo profesional, y lo que
                      nos ha llevado a merecer y conseguir la confianza continua
                      de grandes empresas, resumimos en dos puntos esta
                      experiencia:
                    </p>

                    <p>
                      1. Trabajar con compromiso como si formaramos parte de la
                      organización del cliente entendiendo sus procesos, sus
                      objetivos y su visión.
                    </p>

                    <p>
                      2. Implantar soluciones tecnológicas con conocimiento de
                      las alternativas del mercado, criterio para seleccionar la
                      más adecuada y razonable para el cliente, independencia de
                      intereses ajenos a los del cliente, competencia y
                      flexibilidad creativa en la ejecución y tenacidad hasta
                      conseguir el éxito.
                    </p>
                  </article>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Somos;
