import * as React from "react";

class ServicioItem extends React.Component {
  state = {
    show: false,
  };

  toggle = () =>
    this.setState((currentState) => ({ show: !currentState.show }));

  render() {
    return (
      <article className="services" onClick={this.toggle}>
        <div className="ico-services">
          <img
            id="infoimg"
            src={process.env.PUBLIC_URL + this.props.image}
            alt=""
          />
        </div>
        <h2>
          <a id="infoindex" href="#/">
            {this.props.title}
          </a>
        </h2>
        {this.state.show && <p>{this.props.text}</p>}
      </article>
    );
  }
}

export default ServicioItem;
