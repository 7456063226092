import * as React from "react";
import Iframe from 'react-iframe'

class Contacto extends React.Component {
  render() {
    return <section className="content" >

<Iframe url="https://ventas.netwon.cl/contacto_netwon" 
       width="100%"
       height="2000px"
       />

    </section>;
  }
}

export default Contacto;
