import * as React from "react";

class Footer extends React.Component {
  render() {
    return (
      <footer className="footer">
        <div className="row justify-content-between">
          <div className="col-8 text-center">© 2020 Netwon Services Spa.</div>
          <div className="col-4">
            <ul className="social-icons">
              <li>
                <a
                  className="instagram"
                  data-original-title="instagram"
                  href="https://instagram.com/netwonservices/"
                >&nbsp;</a>
              </li>

              <li>
                <a
                  className="facebook"
                  data-original-title="facebook"
                  href="https://www.facebook.com/NetwonServices/"
                >&nbsp;</a>
              </li>

              <li>
                <a
                  className="linkedin"
                  data-original-title="linkedin"
                  href="https://www.linkedin.com/company/netwonservices"
                >&nbsp;</a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
