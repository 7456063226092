import * as React from "react";
import InfiniteCarousel from "react-leaf-carousel";
/* https://github.com/leaffm/react-infinite-carousel  */

class sliderCarrousel extends React.Component {
  render() {
    /*
    const breakpoints = [
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
    ];
    */


    return (
      <InfiniteCarousel
       /* {...breakpoints} */
        dots={false}
        showSides={true}
        sidesOpacity={0.5}
        sideSize={0.1}
        slidesToScroll={1}
        slidesToShow={4}
        scrollOnDevice={true}
        autoCycle={true}
      >
        <div>
          <img
            alt=""

            src={process.env.PUBLIC_URL + "/img/carousel/Cisco.png"}
          />
        </div>
        <div>
          <img
            alt=""

            src={process.env.PUBLIC_URL + "/img/carousel/Fortinet.png"}
          />
        </div>
        <div>
          <img
            alt=""

            src={process.env.PUBLIC_URL + "/img/carousel/Meraki.png"}
          />
        </div>
        <div>
          <img
            alt=""

            src={process.env.PUBLIC_URL + "/img/carousel/Microsoft.png"}
          />
        </div>
        <div>
          <img
            alt=""

            src={process.env.PUBLIC_URL + "/img/carousel/Aws.png"}
          />
        </div>
        <div>
          <img
            alt=""

            src={process.env.PUBLIC_URL + "/img/carousel/Vmware.png"}
          />
        </div>
        <div>
          <img
            alt=""

            src={process.env.PUBLIC_URL + "/img/carousel/Dell.png"}
          />
        </div>
        <div>
          <img
            alt=""
           
            src={process.env.PUBLIC_URL + "/img/carousel/Opengear.png"}
          />
        </div>
        <div>
          <img
            alt=""
   
            src={process.env.PUBLIC_URL + "/img/carousel/Sophos.png"}
          />
        </div>
      </InfiniteCarousel>
    );
  }
}

export default sliderCarrousel;
