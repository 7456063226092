import * as React from "react";
import Slider from "./sliderCarrousel";
import ServicioItem from "./servicioItem.js";

class Inicio extends React.Component {
  constructor(props: any) {
    super(props);
    this.upvote = this.upvote.bind(this);
  }

  upvote(e: { preventDefault: () => void }) {
    // function upvote
    e.preventDefault();
    return false;
  }

  render() {
    return (
      <section className="content">
        <div className="banner">
          <h1>
            Net<span style={{ color: "#498fc0" }}>won</span> Services
          </h1>
        </div>

        <div className="sliderCarrousel">
          <p>Aliados Estratégicos</p>
          <Slider />
        </div>

        <div className="content-services" id="soluciones">
          <ServicioItem
            image="/img/network.png"
            title="COMUNICACIONES INTERNAS"
            text="  Diseñamos, instalamos y damos soporte a redes internas en las
              sedes de nuestros clientes ofreciendo: Redes de área local (LAN)
              conmutadas y segmentadas desde la infraestructura de cableado, los
              conmutadores (Switches), enrutadores (Routers) y equipos de
              cobertura radio (WiFi o DECT) y Redes de área local inalámbrica
              (WLAN)."
          />

          <ServicioItem
            image="/img/wnetwork.png"
            title="COMUNICACIONES EXTERNAS"
            text="Creamos comunicaciones entre las sedes de nuestros clientes a
              través de Internet o de redes internas de operador, Sobre enlaces
              punto a punto , Redes privadas virtuales (VPN) IP sobre la
              infraestructura interna del operador (MPLS) o Redes privadas
              virtuales (VPN) creando túneles encriptados sobre Internet."
          />

          <ServicioItem
            image="/img/network2.png"
            title="COMUNICACIONES INALAMBRICAS"
            text="Soluciones en ingeniería e implantación de comunicaciones WiFi y
              entornos radio. Equipamiento especializado equilibrando en cada
              caso: Coste, Cobertura, Volumen y criticidad de tráfico, Gestión
              de espectro radioeléctrico disponible e interferencias."
          />
        </div>

        <div className="message">
          <p>Soluciones IT adaptadas a sus necesidades.</p>
          <p>Sistemas de comunicación fiables.</p>
        </div>
      </section>
    );
  }
}

export default Inicio;
