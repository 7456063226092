import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import browserHistory from "react-dom";
import 'bootstrap/dist/css/bootstrap.min.css';

import Navbar from "./components/menu";
import Inicio from "./components/inicio";
import Servicios from "./components/servicios";
import QuienesSomos from "./components/somos";
import Contacto from "./components/contacto";
import Catalogo from "./components/catalogo";
import Servicio from "./components/servicio";
import Footer from "./components/footer";
import WhatsApp from "./components/whatsapp";
const supportsHistory = "pushState" in window.history;

/*
About router https://stackoverflow.com/questions/46684607/gitlab-pages-and-react-router
        /*  <Route exact path="/" component={Inicio} />
          <Route exact path="*" component={Inicio} />

*/

function App() {
  return (
   <div className="container-fluid">
   <Router  basename={process.env.PUBLIC_URL} forceRefresh={!supportsHistory}>
      <div >
        <Navbar />
        <hr />
        <Switch>
          <Route history={browserHistory} path="/netwon" component={Inicio} />
          <Route history={browserHistory} path="/Inicio" component={Inicio} />
          <Route history={browserHistory} path="/Servicios" component={Servicios} />
          <Route history={browserHistory} path="/Somos" component={QuienesSomos} />
          <Route history={browserHistory} path="/Catalogo" component={Catalogo} />
          <Route history={browserHistory} path="/Contacto" component={Contacto} />
          <Route exact path="/" component={Inicio} />
          <Route exact path="*" component={Inicio} />
        </Switch>
      </div>
      <Servicio />
      <Footer />
    </Router>
    <WhatsApp />
    </div>
  );
}

export default App;
