import * as React from "react";
import { NavLink } from "react-router-dom";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

/*
export interface HelloWorldProps {
  userName: string;
  lang: string;
}
export const Menu = (props: HelloWorldProps) => (
  <h1>
    Hi {props.userName} from React! Welcome to {props.lang}!
  </h1>
);
*/

/*
Info About it:
https://stackoverflow.com/questions/35687353/react-bootstrap-link-item-in-a-navitem
*/

class Menu extends React.Component {
  render() {
    return (
      <header>
        <Navbar expand="md"  style={{fontSize:"larger"}} >
          <Navbar.Brand>
            <LinkContainer to="/Inicio">
              <img
                src={process.env.PUBLIC_URL + "/img/logo.png"}
                width="160px"
                height="60px"
                alt=""
              />
            </LinkContainer>
          </Navbar.Brand>
          <Nav className="ml-auto">
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav.Link as={NavLink} to="/inicio" exact>
                Inicio
              </Nav.Link>
              <Nav.Link as={NavLink} to="/servicios" exact>
                Servicios
              </Nav.Link>
              <Nav.Link as={NavLink} to="/somos" exact>
                Quiénes somos
              </Nav.Link>
              <NavDropdown title="Campañas" id="collasible-nav-dropdown" >
                <Nav.Link
                  as={NavLink}
                  to="/catalogo"
                  exact
                  style={{color:"#212529"}}
                >
                  Cisco Designed
                </Nav.Link>
              </NavDropdown>
              <Nav.Link as={NavLink} to="/contacto" exact>
                Contacto
              </Nav.Link>
            </Navbar.Collapse>
          </Nav>
        </Navbar>
      </header>
    );
  }
}

export default Menu;
