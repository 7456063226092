import * as React from "react";

class Servicio extends React.Component {
  render() {
    return (
      <section className="pre-footer">
        <div className="transparent">
          <div className="nav-footer">
            <h2>
              <a href="#/">Soluciones</a>
            </h2>

            <ul>
              <li>
                <a href="#/">Comunicaciones Internas</a>
              </li>
              <li>
                <a href="#/">Comunicaciones Externas</a>
              </li>
              <li>
                <a href="#/">Comunicaciones Inalámbricas</a>
              </li>
            </ul>
          </div>

          <div className="nav-footer">
            <h2>
              <a href="#/">Servicios</a>
            </h2>

            <ul>
              <li>
                <a href="#/">
                  Soluciones Informáticas
                </a>
              </li>
              <li>
                <a href="#/">
                  Mantenimiento y Soporte
                </a>
              </li>
              <li>
                <a href="#/">Servicios Outsourcing</a>
              </li>
            </ul>
          </div>

          <div className="nav-footer">
            <h2>
            <a href="servicios.html">&nbsp;</a>
            </h2>

            <ul>
              <li>
                <a href="#/">Seguridad IT</a>
              </li>
              <li>
                <a href="#/">Networking</a>
              </li>
              <li>
                <a href="#/">Cableado Estructurado</a>
              </li>
            </ul>
          </div>
        </div>
      </section>
    );
  }
}

export default Servicio;
