import * as React from "react";
import Iframe from 'react-iframe'

class Catalogo extends React.Component {
  render() {
    return <section className="content" >

<Iframe url="https://sites.ziftsolutions.com/cisco.ziftsolutions.com/8a99837d74d4e6950174d50aabb90700" 
       width="100%"
       height="3000px"
       />

    </section>;
  }
}

export default Catalogo;
